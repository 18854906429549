<template>
  <v-dialog :value="headerModal" width="500" persistent>
    <v-card>
      <v-card-title class="justify-center">Kolom toevoegen</v-card-title>
      <v-card-text>
        <div
          v-for="(header, index) of headers"
          :key="index"
          class="d-flex justify-center"
        >
          <v-btn @click="insertHeader(header)" outlined text>{{
            header.text
          }}</v-btn>
        </div>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="primary" @click="closeHeaderModal">Sluiten</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "headerModal",
  props: {
    headerModal: Boolean,
    headerType: String
  },
  data() {
    return {
      headers: [
        { text: "Status", value: "status", width: 150 },
        { text: "Ordernr", value: "orderNumber" },
        { text: "Factuur", value: "invoiceNumber" },
        { text: "Naam", value: "name" },
        { text: "Datum", value: "date" },
        { text: "Leverdatum", value: "deliveryDate" },
        { text: "Levertijd", value: "deliveryTime" },
        { text: "Route", value: "route", width: 150 },
        { text: "Stad", value: "client.city" },
        { text: "Postcode", value: "client.postalcode" },
        { text: "Factuur frequentie", value: "client.invoiceFrequency" },
        { text: "Totaal excl.", value: "netTotal" },
        { text: "Totaal incl.", value: "grossTotal" },
        { text: "Kratten geleverd", value: "totalCrates" },
        { text: "Kratten retour", value: "returnedCrates" },
        { text: "Print", value: "printed", width: 50, sortable: false },
        { text: "Email", value: "emailed", width: 50, sortable: false },
        { text: "Kaart", value: "maps", width: 50, sortable: false },
        { text: "Opmerking", value: "notes", width: 50, sortable: false },
        { text: "Acties", value: "actions", width: 110, sortable: false }
      ]
    }
  },
  methods: {
    insertHeader(header) {
      this.$emit("insert-header", this.headerType, header)
      this.closeHeaderModal()
    },
    closeHeaderModal() {
      this.$emit("close-header-modal")
    }
  }
}
</script>

<style scoped>
</style>