<template>
  <v-dialog :value="columnModal" width="500" persistent>
    <v-card>
      <v-card-title class="justify-center">Kolom toevoegen</v-card-title>
      <v-card-text>
        <div v-for="(column, index) of columns" :key="index" class="d-flex justify-center">
          <v-btn @click="insertColumn(column)" outlined text>{{column.text}}</v-btn>
        </div>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="primary" @click="closeColumnModal">Sluiten</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "columnModal",
  props: {
    columnModal: Boolean,
    columnType: String
  },
  data() {
    return {
      columns: [
        { text: "Artikelnr", type: "number", value: "itemNumber", width: 50 },
        { text: "Artikel", type: "text", value: "name", width: 100 },
        { text: "Omschrijving", type: "html", value: "description", width: 200 },
        { text: "Aantal", type: "number", value: "amount", width: 50 },
        { text: "Prijs", type: "currency", value: "price", width: 50 },
        { text: "Eenheid", type: "text", value: "unit", width: 50 },
        { text: "Inhoud", type: "number", value: "unitAmount", width: 50 },
        { text: "BTW", type: "vat", value: "VAT", width: 50 },
        {
          text: "Subtotaal",
          type: "currency",
          value: "netSubtotal",
          width: 50
        },
        { text: "Totaal", type: "currency", value: "grossSubtotal", width: 50 },
        { text: "Lotnr", type: "number", value: "lotNumber", width: 50 },
        { text: "GN-code", type: "export", value: "commodityCode", width: 50 },
      ]
    }
  },
  methods: {
    insertColumn(column) {
      this.$emit("insert-column", this.columnType, column)
      this.closeColumnModal()
    },
    closeColumnModal() {
      this.$emit("close-column-modal")
    }
  }
}
</script>

<style scoped>
</style>