<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Instellingen</v-card-title>
          <v-tabs vertical>
            <v-tab>Bedrijfsgegevens</v-tab>
            <v-tab>Artikelen</v-tab>
            <v-tab>Offertes</v-tab>
            <v-tab>Orders</v-tab>
            <v-tab>Facturen</v-tab>
            <v-tab>Kasboek</v-tab>
            <v-tab>Categorieën</v-tab>
            <v-tab>Routes</v-tab>
            <v-tab>Nummering</v-tab>
            <v-tab>Email</v-tab>
            <v-tab>Emailsjablonen</v-tab>
            <v-tab>Communicatie</v-tab>
            <v-tab>Traceerbaarheid</v-tab>
            <v-tab>Printers</v-tab>
            <v-tab>Zoeken</v-tab>
            <v-tab>Account</v-tab>
            <v-tab-item>
              <fieldset>
                <v-text-field label="Naam" v-model="settings.company.name"></v-text-field>
                <v-text-field label="Adres" v-model="settings.company.address"></v-text-field>
                <v-text-field label="Postcode" v-model="settings.company.postalcode"></v-text-field>
                <v-text-field label="Plaats" v-model="settings.company.city"></v-text-field>
                <v-text-field label="Land" v-model="settings.company.country"></v-text-field>
                <v-text-field label="Telefoon" v-model="settings.company.phone"></v-text-field>
                <v-text-field label="Email" v-model="settings.company.email"></v-text-field>
                <v-text-field label="Website" v-model="settings.company.website"></v-text-field>
                <v-text-field label="KvK" v-model="settings.company.COCNumber"></v-text-field>
                <v-text-field label="BTW" v-model="settings.company.VATNumber"></v-text-field>
                <v-text-field label="EORI-nr" v-model="settings.company.EORINumber"></v-text-field>
                <v-text-field label="REX-nr" v-model="settings.company.REXNumber"></v-text-field>
                <v-textarea label="REX-verklaring" v-model="settings.company.REXStatement"></v-textarea>
                <v-text-field label="IBAN 1" v-model="settings.company.IBAN1"></v-text-field>
                <v-text-field label="BIC 1" v-model="settings.company.BIC1"></v-text-field>
                <v-text-field label="Rekeninghouder 1" v-model="settings.company.accountHolder1"></v-text-field>
                <v-text-field label="IBAN 2" v-model="settings.company.IBAN2"></v-text-field>
                <v-text-field label="BIC 2" v-model="settings.company.BIC2"></v-text-field>
                <v-text-field label="Rekeninghouder 2" v-model="settings.company.accountHolder2"></v-text-field>
                <v-text-field label="Logo" v-model="settings.company.logo"></v-text-field>
                <v-file-input @change="previewECNumberImage" label="EG-nummer afbeelding" accept="image/png"
                  placeholder="Selecteer een afbeelding" :rules="fileRules">
                </v-file-input>
                <v-text-field label="EG-nummer" v-model="settings.company.ECNumber">
                </v-text-field>
                <img :src="settings.company.ECNumberImage" alt="" />
              </fieldset>
            </v-tab-item>
            <v-tab-item>
              <h1>Artikelen</h1>

              <fieldset class="fieldset-flex">
                <legend></legend>
              </fieldset>
            </v-tab-item>
            <v-tab-item>
              <fieldset class="fieldset-flex">
                <legend>Kolommen</legend>
                <draggable v-model="settings.quoteHeaders" @start="drag = true" @end="drag = false">
                  <div v-for="(column, index) in settings.quoteHeaders" :key="index" class="d-flex pa-2">
                    <v-text-field label="Titel" class="column-text" v-model="column.text">
                    </v-text-field>
                    <v-text-field label="Breedte" :value="column.width"
                      @change="updateHeaderWidth('quoteHeaders', index, $event)"></v-text-field>
                    <v-icon @click="deleteHeader('quoteHeaders', index)">mdi-delete</v-icon>
                  </div>
                </draggable>
                <div class="d-flex justify-center">
                  <v-icon @click="openHeaderModal('quoteHeaders')" color="primary" x-large>mdi-plus-box</v-icon>
                </div>
              </fieldset>
              <fieldset>
                <legend>Kolommen PDF</legend>
                <draggable v-model="settings.quoteColumns" @start="drag = true" @end="drag = false">
                  <div v-for="(column, index) in settings.quoteColumns" :key="index" class="d-flex pa-2">
                    <v-text-field label="Titel" class="column-text" v-model="column.text">
                    </v-text-field>
                    <v-text-field label="Breedte" :value="column.width"
                      @change="updateColumnWidth('quoteColumns', index, $event)"></v-text-field>
                    <v-icon @click="deleteColumn('quoteColumns', index)">mdi-delete</v-icon>
                  </div>
                </draggable>
                <div class="d-flex justify-center">
                  <v-icon @click="openColumnModal('quoteColumns')" color="primary" x-large>mdi-plus-box</v-icon>
                </div>
              </fieldset>
            </v-tab-item>
            <v-tab-item>
              <div class="d-flex flex-wrap">
                <fieldset class="fieldset-flex">
                  <legend>Kolommen</legend>
                  <draggable v-model="settings.orderHeaders" @start="drag = true" @end="drag = false">
                    <div v-for="(column, index) in settings.orderHeaders" :key="index" class="d-flex pa-2">
                      <v-text-field label="Titel" class="column-text" v-model="column.text">
                      </v-text-field>
                      <v-text-field label="Breedte" :value="column.width" @change="
                        updateHeaderWidth('orderHeaders', index, $event)
                        "></v-text-field>
                      <v-icon @click="deleteHeader('orderHeaders', index)">mdi-delete</v-icon>
                    </div>
                  </draggable>
                  <div class="d-flex justify-center">
                    <v-icon @click="openHeaderModal('orderHeaders')" color="primary" x-large>mdi-plus-box</v-icon>
                  </div>
                </fieldset>
                <fieldset class="fieldset-flex">
                  <legend>Kolommen PDF</legend>
                  <draggable v-model="settings.orderColumns" @start="drag = true" @end="drag = false">
                    <div v-for="(column, index) in settings.orderColumns" :key="index" class="d-flex pa-2">
                      <v-text-field label="Titel" class="column-text" v-model="column.text">
                      </v-text-field>
                      <v-text-field label="Breedte" :value="column.width" @change="
                        updateColumnWidth('orderColumns', index, $event)
                        "></v-text-field>
                      <v-icon @click="deleteColumn('orderColumns', index)">mdi-delete</v-icon>
                    </div>
                  </draggable>
                  <div class="d-flex justify-center">
                    <v-icon @click="openColumnModal('orderColumns')" color="primary" x-large>mdi-plus-box</v-icon>
                  </div>
                </fieldset>
              </div>
              <fieldset>
                <legend>Voettekst</legend>
                <v-textarea v-model="settings.orderFooter"></v-textarea>
              </fieldset>
            </v-tab-item>
            <v-tab-item>
              <fieldset>
                <legend>Standaard sjabloon</legend>
                <v-select label="Sjabloon" :items="invoiceTemplates" v-model="settings.invoiceTemplate"></v-select>
              </fieldset>
              <fieldset>
                <legend>iDEAL</legend>
                <v-checkbox label="iDEAL inschakelen" v-model="settings.ideal.enabled"></v-checkbox>
                <legend>Kolommen</legend>
                <draggable v-model="settings.invoiceColumns" @start="drag = true" @end="drag = false">
                  <div v-for="(column, index) in settings.invoiceColumns" :key="index" class="d-flex pa-2">
                    <v-text-field label="Titel" class="column-text" v-model="column.text">
                    </v-text-field>
                    <v-text-field label="Breedte" :value="column.width" @change="
                      updateColumnWidth('invoiceColumns', index, $event)
                      "></v-text-field>
                    <v-icon @click="deleteColumn('invoiceColumns', index)">mdi-delete</v-icon>
                  </div>
                </draggable>
                <div class="d-flex justify-center">
                  <v-icon @click="openColumnModal('invoiceColumns')" color="primary" x-large>mdi-plus-box</v-icon>
                </div>
              </fieldset>
              <fieldset>
                <v-text-field label="Betalingstermijn" v-model.number="settings.payTerms"></v-text-field>
                <v-textarea label="Betalingsvoorwaarden" v-model="settings.company.conditions"></v-textarea>
                <v-text-field @click:append="deletePayMethod(index)" append-icon="mdi-delete"
                  v-for="(payMethod, index) of settings.payMethods" :key="index"
                  v-model="settings.payMethods[index]"></v-text-field>
                <v-icon @click="addPayMethod" x-large>mdi-plus-box</v-icon>
              </fieldset>
            </v-tab-item>
            <v-tab-item>
              <fieldset>
                <legend>Saldo</legend>
                <div class="d-flex" v-for="(led, index) of settings.ledgers" :key="index">
                  <div>{{ led.year }}</div>
                  <v-text-field v-model.number="led.balance"></v-text-field>
                </div>
              </fieldset>
            </v-tab-item>
            <v-tab-item>
              <fieldset>
                <legend>Verkoop categorieën</legend>
                <v-text-field @click:append="deleteCategory(index)" append-icon="mdi-delete"
                  v-for="(category, index) of settings.categories" :key="index"
                  v-model="settings.categories[index]"></v-text-field>
                <div class="d-flex justify-center">
                  <v-btn color="primary" @click="addCategory">
                    <h1>+</h1>
                  </v-btn>
                </div>
              </fieldset>
              <fieldset>
                <legend>Inkoop categorieën</legend>
                <v-text-field @click:append="deletePurchaseCategory(index)" append-icon="mdi-delete"
                  v-for="(category, index) of settings.purchaseCategories" :key="index"
                  v-model="settings.purchaseCategories[index]"></v-text-field>
                <div class="d-flex justify-center">
                  <v-btn color="primary" @click="addPurchaseCategory">
                    <h1>+</h1>
                  </v-btn>
                </div>
              </fieldset>
            </v-tab-item>
            <v-tab-item>
              <fieldset>
                <v-text-field @click:append="deleteRoute(index)" append-icon="mdi-delete"
                  v-for="(route, index) of settings.routes" :key="index"
                  v-model="settings.routes[index]"></v-text-field>
                <div class="d-flex justify-center">
                  <v-btn color="primary" @click="addRoute">
                    <h1>+</h1>
                  </v-btn>
                </div>
              </fieldset>
            </v-tab-item>
            <v-tab-item>
              <fieldset>
                <v-text-field label="Ordernummer" v-model.number="settings.orderNumber"></v-text-field>
                <v-text-field label="Factuurnummer" v-model.number="settings.invoiceNumber"></v-text-field>
                <v-text-field label="Artikelnummer" v-model.number="settings.itemNumber"></v-text-field>
                <v-text-field label="Klantnummer" v-model.number="settings.clientNumber"></v-text-field>
                <v-text-field label="Leveranciernummer" v-model.number="settings.supplierNumber"></v-text-field>
              </fieldset>
            </v-tab-item>
            <v-tab-item>
              <fieldset>
                <v-text-field label="Afzender" v-model="settings.smtp.from"></v-text-field>
                <v-text-field label="Uitgaande server" v-model="settings.smtp.host"></v-text-field>
                <v-text-field label="Poort" v-model.number="settings.smtp.port"></v-text-field>
                <v-text-field label="Afzender" v-model="settings.smtp.from"></v-text-field>
                <v-text-field label="Gebruikersnaam" v-model="settings.smtp.auth.user"></v-text-field>
                <v-text-field label="Wachtwoord" type="password" v-model="settings.smtp.auth.pass"></v-text-field>
              </fieldset>
            </v-tab-item>
            <v-tab-item>
              <fieldset>
                <legend>Offertes</legend>
                <v-tabs v-model="emailQuoteTab">
                  <v-tab>Tekst</v-tab>
                  <v-tab>HTML</v-tab>
                  <v-tabs-items v-model="emailQuoteTab">
                    <v-tab-item><v-textarea label="Offertes" v-model="settings.emailQuoteBody.text"></v-textarea>
                    </v-tab-item>
                    <v-tab-item><vue-editor v-model="settings.emailQuoteBody.html"></vue-editor>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </fieldset>
              <fieldset>
                <legend>Orders</legend>
                <v-tabs v-model="emailOrderTab">
                  <v-tab>Tekst</v-tab>
                  <v-tab>HTML</v-tab>
                  <v-tabs-items v-model="emailOrderTab">
                    <v-tab-item><v-textarea label="Orders" v-model="settings.emailOrderBody.text"></v-textarea>
                    </v-tab-item>
                    <v-tab-item><vue-editor v-model="settings.emailOrderBody.html"></vue-editor>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </fieldset>
              <fieldset>
                <legend>Facturen</legend>
                <v-tabs v-model="emailInvoiceTab">
                  <v-tab>Tekst</v-tab>
                  <v-tab>HTML</v-tab>
                  <v-tabs-items v-model="emailInvoiceTab">
                    <v-tab-item><v-textarea label="Orders" v-model="settings.emailInvoiceBody.text"></v-textarea>
                    </v-tab-item>
                    <v-tab-item><vue-editor v-model="settings.emailInvoiceBody.html"></vue-editor>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </fieldset>
              <fieldset>
                <legend>Te factureren orders</legend>
                <v-tabs v-model="emailOpenOrdersTab">
                  <v-tab>Tekst</v-tab>
                  <v-tab>HTML</v-tab>
                  <v-tabs-items v-model="emailOpenOrdersTab">
                    <v-tab-item><v-textarea label="Orders" v-model="settings.emailOpenOrdersBody.text"></v-textarea>
                    </v-tab-item>
                    <v-tab-item><vue-editor v-model="settings.emailOpenOrdersBody.html"></vue-editor>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </fieldset>
              <fieldset>
                <legend>Openstaande facturen</legend>
                <v-tabs v-model="emailOutstandingInvoicesTab">
                  <v-tab>Tekst</v-tab>
                  <v-tab>HTML</v-tab>
                  <v-tabs-items v-model="emailOutstandingInvoicesTab">
                    <v-tab-item><v-textarea label="Orders"
                        v-model="settings.emailOutstandingInvoicesBody.text"></v-textarea>
                    </v-tab-item>
                    <v-tab-item><vue-editor v-model="settings.emailOutstandingInvoicesBody.html"></vue-editor>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </fieldset>
            </v-tab-item>
            <v-tab-item>
              <fieldset>
                <v-text-field label="Bericht van de dag" v-model="settings.communication.motd"></v-text-field>
              </fieldset>
            </v-tab-item>
            <v-tab-item>
              <fieldset>
                Lotnummer-format([*supplier*][*year*][*month*][*day*][*category*][*item*]):
                <v-text-field label="Format" v-model="settings.traceability.lotNumberFormat"></v-text-field>
              </fieldset>
            </v-tab-item>
            <v-tab-item>
              <fieldset>
                <legend>Labels</legend>
                <v-select label="Labelprinter" :items="printers" v-model="settings.labelPrinter.name" item-text="name"
                  item-value="name"></v-select>
                <v-text-field label="Breedte" :value="pointToMm(settings.labelPrinter.pageSize.width)"
                  @change="updateLabelPrinterPageSizeWidth"></v-text-field>
                <v-text-field label="Hoogte" :value="pointToMm(settings.labelPrinter.pageSize.height)"
                  @change="updateLabelPrinterPageSizeHeight"></v-text-field>
                <v-text-field label="Logo breedte" :value="pointToMm(settings.labelPrinter.logoSize.width)"
                  @change="updateLabelPrinterLogoSizeWidth"></v-text-field>
                <v-text-field label="Logo hoogte" :value="pointToMm(settings.labelPrinter.logoSize.height)"
                  @change="updateLabelPrinterLogoSizeHeight"></v-text-field>
                <v-text-field label="Standaard lettergrootte"
                  v-model="settings.labelPrinter.styles.defaultStyle.fontSize"></v-text-field>
                <v-text-field label="Titel lettergrootte"
                  v-model="settings.labelPrinter.styles.header.fontSize"></v-text-field>
                <v-text-field label="Ondertitel lettergrootte"
                  v-model="settings.labelPrinter.styles.subHeader.fontSize"></v-text-field>
                <v-text-field label="Voettekst lettergrootte"
                  v-model="settings.labelPrinter.styles.footer.fontSize"></v-text-field>
                <v-textarea label="Voettekst" v-model="settings.labelPrinter.footer"></v-textarea>
                <v-text-field label="Marges" v-model="settings.labelPrinter.margins"
                  hint="Links, rechts, boven, onder"></v-text-field>
                <v-text-field label="Afbeelding hoogte"
                  v-model.number="settings.labelPrinter.imageHeight"></v-text-field>
                <v-text-field label="Afbeelding breedte"
                  v-model.number="settings.labelPrinter.imageWidth"></v-text-field>
                <v-select label="Paginastand" v-model="settings.labelPrinter.pageOrientation" :items="[
                  { value: 'portrait', text: 'Portrait' },
                  { value: 'landscape', text: 'Landscape' },
                ]"></v-select>
              </fieldset>
            </v-tab-item>
            <v-tab-item>
              <fieldset>
                <legend>Zoeken naar klant</legend>
                <v-checkbox label="Toon contactpersoon" v-model="settings.searchClient.showContact"></v-checkbox>
              </fieldset>
            </v-tab-item>
            <v-tab-item>
              <fieldset>
                <legend>Wachtwoord wijzigen</legend>
                <v-text-field disabled label="Gebruikersnaam" :value="client.username"></v-text-field>
                <v-text-field label="Nieuw wachtwoord" type="password" v-model="newPassword"></v-text-field>
                <v-text-field label="Herhaal nieuw wachtwoord" type="password"
                  v-model="newPasswordConfirm"></v-text-field>
                <v-btn color="primary" @click="changePassword" :disabled="newPassword !== newPasswordConfirm">
                  Wijzig wachtwoord
                </v-btn>
              </fieldset>
            </v-tab-item>
          </v-tabs>
          <v-footer class="d-flex justify-center">
            <v-btn @click="save" color="primary">Opslaan</v-btn>
          </v-footer>
        </v-card>
      </v-col>
    </v-row>
    <column-modal v-if="columnModal" :columnModal="columnModal" :columnType="columnType" @insert-column="insertColumn"
      @close-column-modal="closeColumnModal"></column-modal>
    <header-modal v-if="headerModal" :headerModal="headerModal" :headerType="headerType" @insert-header="insertHeader"
      @close-header-modal="closeHeaderModal"></header-modal>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import draggable from "vuedraggable";
import columnModal from "@/components/columnModal.vue";
import headerModal from "@/components/headerModal.vue";
import {
  errorHandler,
  fetchGET,
  fetchPOST,
  successHandler
} from "../js/functions";
export default {
  name: "settings",
  data() {
    return {
      settings: {
        categories: [],
        purchaseCategories: [""],
        routes: [],
        communication: {
          motd: ""
        },
        company: {
          name: ""
        },
        smtp: {
          from: "",
          auth: {
            user: "",
            pass: ""
          }
        },
        emailQuoteBody: {},
        emailOrderBody: {},
        emailInvoiceBody: {},
        emailOpenOrdersBody: {},
        emailOutstandingInvoicesBody: {},
        labelPrinter: {
          name: "test2_printer",
          pageSize: {
            width: 0,
            height: 0
          },
          logoSize: {
            width: 0,
            height: 0
          },
          styles: {
            defaultStyle: {
              fontSize: 0
            },
            header: {
              fontSize: 0
            },
            subHeader: {
              fontSize: 0
            },
            footer: {
              fontSize: 0
            }
          }
        },
        searchClient: { showContact: false },
        ledgers: [],
        traceability: {
          lotNumberFormat: ""
        },
        ideal: {
          enabled: false
        },
        payMethods: [],
        packaging: [],
        quoteColumns: [],
        quoteHeaders: [],
        orderColumns: [],
        orderHeaders: [],
        invoiceColumns: [],
        invoiceHeaders: [],
      },
      emailQuoteTab: null,
      emailOrderTab: null,
      emailInvoiceTab: null,
      emailOpenOrdersTab: null,
      emailOutstandingInvoicesTab: null,
      motdTab: null,
      ECNumberImage: null,
      fileRules: [
        value =>
          !value ||
          value.size < 100000 ||
          "Afbeelding moet kleiner zijn dan 100kb"
      ],
      newPassword: "",
      newPasswordConfirm: "",
      columnModal: false,
      headerModal: false,
      columnType: "",
      headerType: ""
    };
  },
  methods: {
    mmToPoints(mm) {
      const MILLIMETERS_IN_INCH = 25.4;
      const POINTS_IN_INCH = 72;
      const inches = mm / MILLIMETERS_IN_INCH;
      let points = inches * POINTS_IN_INCH;
      return parseFloat(points.toFixed(2));
    },
    pointToMm(point) {
      const MILLIMETERS_IN_INCH = 25.4;
      const POINTS_IN_INCH = 72;
      const inches = point / POINTS_IN_INCH;
      let mm = inches * MILLIMETERS_IN_INCH;
      return parseFloat(mm.toFixed(2));
    },
    updateLabelPrinterPageSizeWidth(e) {
      let width = this.mmToPoints(e);
      this.settings.labelPrinter.pageSize.width = width;
    },
    updateLabelPrinterPageSizeHeight(e) {
      let height = this.mmToPoints(e);
      this.settings.labelPrinter.pageSize.height = height;
    },
    updateLabelPrinterLogoSizeWidth(e) {
      let width = this.mmToPoints(e);
      this.settings.labelPrinter.logoSize.width = width;
    },
    updateLabelPrinterLogoSizeHeight(e) {
      let height = this.mmToPoints(e);
      this.settings.labelPrinter.logoSize.height = height;
    },
    async fetchSettings() {
      let res = await fetchGET("settings", {});
      Object.assign(this.settings, res)
    },
    addCategory() {
      this.settings.categories.push("");
    },
    deleteCategory(index) {
      this.settings.categories.splice(index, 1);
    },
    addPurchaseCategory() {
      this.settings.purchaseCategories.push("");
    },
    deletePurchaseCategory(index) {
      this.settings.purchaseCategories.splice(index, 1);
    },
    addRoute() {
      this.settings.routes.push("");
    },
    deleteRoute(index) {
      this.settings.routes.splice(index, 1);
    },
    addPayMethod() {
      this.settings.payMethods.push("");
    },
    deletePayMethod(index) {
      this.settings.payMethods.splice(index, 1);
    },
    previewECNumberImage(e) {
      const file = e;
      const reader = new FileReader();
      let rawImg;
      reader.onloadend = () => {
        rawImg = reader.result;
        this.settings.company.ECNumberImage = rawImg;
      };
      this.ECNumberImage = reader.readAsDataURL(file);
    },
    async save() {
      try {
        let response = await fetchPOST("updateSettings", this.settings);
        if (response.nModified === 0) throw "updateSettings";
        this.$store.commit("settings", this.settings);
        successHandler("Instellingen opgeslagen");
      } catch (e) {
        errorHandler(e, "Instellingen niet opgeslagen");
      }
    },
    async changePassword() {
      try {
        let result = await fetchPOST("changePassword", {
          ...this.client,
          password: this.newPassword
        });
        if (result.nModified === 0) throw "changePassword";
        successHandler("Wachtwoord gewijzigd");
      } catch (e) {
        errorHandler(e, "Wachtwoord niet gewijzigd");
      }
    },
    deleteColumn(columnType, index) {
      this.settings[columnType].splice(index, 1);
    },
    insertColumn(columnType, column) {
      if (this.settings[columnType] && this.settings[columnType].length)
        this.settings[columnType].push(column);
      else this.settings[columnType] = [column];
    },
    updateColumnWidth(columnType, index, e) {
      if (parseInt(e) > 0) {
        this.settings[columnType][index].width = parseInt(e);
      } else this.settings[columnType][index].width = e;
    },
    openColumnModal(columnType) {
      this.columnType = columnType;
      this.columnModal = true;
    },
    closeColumnModal() {
      this.columnModal = false;
    },
    deleteHeader(headerType, index) {
      this.settings[headerType].splice(index, 1);
    },
    insertHeader(headerType, header) {
      if (this.settings[headerType] && this.settings[headerType].length)
        this.settings[headerType].push(header);
      else this.settings[headerType] = [header];
    },
    updateHeaderWidth(headerType, index, e) {
      if (parseInt(e) > 0) {
        this.settings[headerType][index].width = parseInt(e);
      } else this.settings[headerType][index].width = e;
    },
    openHeaderModal(headerType) {
      this.headerType = headerType;
      this.headerModal = true;
    },
    closeHeaderModal() {
      this.headerModal = false;
    },
  },
  computed: {
    printers() {
      return this.$store.state.printers;
    },
    client() {
      return this.$store.state.client;
    },
    invoiceTemplates() {
      return this.$store.state.settings.invoiceTemplates;
    },
  },
  async mounted() {
    await this.fetchSettings();
  },
  components: {
    VueEditor,
    draggable,
    columnModal,
    headerModal
  }
};
</script>
<style scoped>
fieldset {
  margin: 0.5rem;
}

.column-text {
  width: 500px;
}

.fieldset-flex {
  width: 300px;
}
</style>